@import 'common';
@import 'bootstrap/scss/tooltip';

.tooltip {
	letter-spacing: remsize(0.5);
	&.show {
		opacity: 1;
	}
}

$directions: top bottom, right left, bottom top, left right;

@each $direction, $opposite-direction in $directions {
	.bs-tooltip-#{$direction} {
		margin-#{$opposite-direction}: 4px;
		> .arrow::before {
			@include lightTheme(false) {
				border-#{$direction}-color: $dark-black;
			}
			@include darkTheme {
				border-#{$direction}-color: $white;
			}
		}
	}
}

.tooltip-inner,
.tooltip-inner .tooltip-header {
	@include lightTheme {
		background-color: $dark-black;
		color: $white-strong;
	}
	@include darkTheme {
		background-color: $white;
		color: $black-strong;
	}
}
